<template>
  <div class="devices">
    <panes>
      <template v-slot:left>
        <div class="device-list">
          <DeviceTable
            v-on:rowClick="handleRowClick"
            :events="deviceEvent"
            :unapproved="true"
            title="Unapproved Devices"
          />
        </div>
      </template>
      <template v-slot:right>
        <div class="device-view">
          <EditDevice
            v-if="selected !== null && typeof selected === 'string'"
            :device-id="selected"
          />
        </div>
      </template>
    </panes>
  </div>
</template>

<script>
import DeviceTable from '@/components/Table/Device.vue';
import Panes from '@/components/Panes/Panes.vue';
import EditDevice from '../../components/Device/Edit.vue';

export default {
  name: 'Unapproved',
  components: {
    EditDevice,
    DeviceTable,
    Panes,
  },
  data() {
    return {
      selected: null,
      webSocket: null,
      deviceEvent: null,
    };
  },
  methods: {
    handleRowClick(id) {
      this.selected = id;
    },
  },
};
</script>

<style lang="scss" scoped>
.devices {
  height: 100%;
}

.device-list {
  height: 100%;
}

.device-view {
  height: 100%;
  overflow-x: auto;
}
</style>
